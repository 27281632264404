import { startOfMonth, subDays, subMonths, subYears } from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { fetchGraphData } from "../../../store/marketing/volume/actions"
import Linechart from "../../AllCharts/chartist/barchart"
import Linechart2 from "../../AllCharts/chartist/barchart2"
import CommonComponent from "../../CommonComponent"
import ErrorMessage from "../../Common/ErrorMessage"
import { useLocation, useNavigate } from "react-router-dom"

Linechart.defaultProps = {
  labels: [],
  data: [],
}

const MarketingVolume = () => {
  const [locId, setLoc] = useState("")
  const [goodsBrand, setGoodsBrand] = useState(null)
  const [timePeriod, setTimePeriod] = useState("日")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [vendor_id, setVendor] = useState(null)
  const [locGroup, setLocGroup] = useState(null)

  const graphData = useSelector(state => state.marketingVolumeReducer.graphData)
  const isLoading = useSelector(state => state.marketingVolumeReducer.loading)

  const dispatch = useDispatch()

  const results = graphData && graphData.data ? graphData.data.results : []
  const labels = results.length ? results.map(item => item.month) : []
  const data = results.length ? results.map(item => item.count) : []
  const last_results =
    graphData && graphData.data ? graphData.data.last_results : []
  const last_data = last_results.length
    ? last_results.map(item => item.count)
    : []
  const label = graphData && graphData.data ? graphData.data.label : ""
  const last_label =
    graphData && graphData.data ? graphData.data.last_label : ""
  const results_price =
    graphData && graphData.data ? graphData.data.results_price : []
  const data_price = results_price.length
    ? results_price.map(item => item.count)
    : []
  const last_results_price =
    graphData && graphData.data ? graphData.data.last_results_price : []
  const last_data_price = last_results_price.length
    ? last_results_price.map(item => item.count)
    : []

  // navigate 関数のインスタンスを取得
  const navigate = useNavigate()

  // クエリパラメータを解析する関数
  const query = new URLSearchParams(location.search)
  const month = query.get("month")
  const locIdQuery = query.get("loc_id")

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return currentDate
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  useEffect(() => {
    if (locId !== "" || locGroup !== null) {
      dispatch(
        fetchGraphData(goodsBrand, locId, timePeriod, currentDate, previousDate, locGroup)
      )
    }
  }, [goodsBrand, locId, currentDate, locGroup])

  const handleBarClick = barIndex => {
    // クリックされたバーのラベルに基づいて日付情報を取得
    let selectedLabel

    if (timePeriod === "時") {
      // currentDate を 'YYYY-MM-DD' 形式に変換
      const dateObj = new Date(currentDate)
      selectedLabel = dateObj.toISOString().split("T")[0]
    } else if (timePeriod === "日") {
      // labels[barIndex] から最初の10文字を抜き取る
      selectedLabel = labels[barIndex].substring(0, 10)
    } else {
      // 通常通り labels 配列からラベルを取得
      selectedLabel = labels[barIndex]

      // selectedLabel を年と月に分割
      const [year, month] = selectedLabel.split("-").map(num => parseInt(num))

      // 指定された年月の次の月の1日を取得
      const nextMonth = new Date(year, month, 1)

      // その日付から1日を引くことで、指定された月の最後の日になる
      const lastDayOfMonth = new Date(nextMonth - 1)

      // selectedLabel を月末日の形式に更新
      selectedLabel = `${lastDayOfMonth.getFullYear()}-${String(
        lastDayOfMonth.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDayOfMonth.getDate()).padStart(2, "0")}`
    }

    navigate(`/sales-daily?month=${selectedLabel}&loc_id=${locId}`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="販売数量推移" />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary mx-auto" style={{ maxWidth: 900 }}>
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999, // 必要に応じて、他の要素より前面に表示されるように設定します
                      }}
                    >
                      <div style={{ color: "white", fontSize: "2em" }}>
                        Loading...
                      </div>
                    </div>
                  )}
                  <CommonComponent
                    goodsBrand={goodsBrand}
                    setGoodsBrand={setGoodsBrand}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                    past_loc={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Card className="mx-auto" style={{ maxWidth: 1200 }}>
                <CardBody>
                  <CardTitle>販売数量推移</CardTitle>
                  <Linechart
                    labels={labels}
                    data={data}
                    last_data={last_data}
                    label={label}
                    last_label={last_label}
                    onBarClick={handleBarClick}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={12}>
              <Card className="mx-auto" style={{ maxWidth: 1200 }}>
                <CardBody>
                  <CardTitle>販売金額推移</CardTitle>
                  <Linechart2
                    labels={labels}
                    data={data_price}
                    last_data={last_data_price}
                    label={label}
                    last_label={last_label}
                    onBarClick={handleBarClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MarketingVolume
