import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_GRAPH_DATA, FETCH_IPCS_DATA } from "./actionTypes"
import { fetchGraphDataSuccess, fetchGraphDataError } from "./actions"

//Include Both Helper File with needed methods
import { getFlows } from "../../helpers/backend_helper"

function* fetchGraphDataSaga(action) {
  try {
    const { goodsBrand, locId, timePeriod, currentDate, previousDate, locGroup } =
      action.payload
    const graphData = yield call(
      getFlows,
      goodsBrand,
      locId,
      timePeriod,
      currentDate,
      previousDate,
      locGroup
    )

    if (graphData.data === undefined) {
      throw new Error("No Data Found")
    }

    yield put(fetchGraphDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGraphDataError(error))
  }
}

function* FlowsSaga() {
  yield takeEvery(FETCH_GRAPH_DATA, fetchGraphDataSaga)
}

export default FlowsSaga
