// sagas.js
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { 
  fetchLocationGroups, 
  updateLocationGroup, 
  deleteLocationGroup,
  registerLocationGroup,
  fetchLocationGroupDetails 
} from "../../helpers/backend_helper";

function* fetchLocationGroupsSaga() {
  try {
    const groups = yield call(fetchLocationGroups);
    yield put(actions.fetchLocationGroupsSuccess(groups));
  } catch (error) {
    yield put(actions.fetchLocationGroupsError(error));
  }
}

function* updateLocationGroupSaga(action) {
  try {
    const { locationId, groupData } = action.payload;
    const updatedGroup = yield call(updateLocationGroup, locationId, groupData);
    yield put(actions.updateLocationGroupSuccess(updatedGroup));
    yield put(actions.fetchLocationGroups());  // グループリストを更新
  } catch (error) {
    yield put(actions.updateLocationGroupError(error));
  }
}

function* deleteLocationGroupSaga(action) {
  try {
    const { locationId, groupId } = action.payload;
    yield call(deleteLocationGroup, locationId, groupId);
    yield put(actions.deleteLocationGroupSuccess(locationId, groupId));
    yield put(actions.fetchLocationGroups());  // グループリストを更新
  } catch (error) {
    yield put(actions.deleteLocationGroupError(error));
  }
}

function* createLocationGroupSaga(action) {
  try {
    const newGroup = yield call(registerLocationGroup, { group_name: action.payload });
    yield put(actions.createLocationGroupSuccess(newGroup));
    yield put(actions.fetchLocationGroups());  // グループリストを更新
  } catch (error) {
    yield put(actions.createLocationGroupError(error));
  }
}

function* fetchLocationGroupDetailsSaga(action) {
  try {
    const details = yield call(fetchLocationGroupDetails, action.payload);
    yield put(actions.fetchLocationGroupDetailsSuccess(details));
  } catch (error) {
    yield put(actions.fetchLocationGroupDetailsError(error));
  }
}

export default function* locationGroupsSaga() {
  yield takeEvery(actionTypes.FETCH_LOCATION_GROUPS, fetchLocationGroupsSaga);
  yield takeEvery(actionTypes.UPDATE_LOCATION_GROUP, updateLocationGroupSaga);
  yield takeEvery(actionTypes.DELETE_LOCATION_GROUP, deleteLocationGroupSaga);
  yield takeEvery(actionTypes.CREATE_LOCATION_GROUP, createLocationGroupSaga);
  yield takeEvery(actionTypes.FETCH_LOCATION_GROUP_DETAILS, fetchLocationGroupDetailsSaga);
}
