// actions.js
import * as actionTypes from "./actionTypes"

export const fetchLocationGroups = () => ({
  type: actionTypes.FETCH_LOCATION_GROUPS,
})

export const fetchLocationGroupsSuccess = groups => ({
  type: actionTypes.FETCH_LOCATION_GROUPS_SUCCESS,
  payload: groups,
})

export const fetchLocationGroupsError = error => ({
  type: actionTypes.FETCH_LOCATION_GROUPS_ERROR,
  error,
})

export const updateLocationGroup = (locationId, groupData) => ({
  type: actionTypes.UPDATE_LOCATION_GROUP,
  payload: { locationId, groupData },
})

export const updateLocationGroupSuccess = group => ({
  type: actionTypes.UPDATE_LOCATION_GROUP_SUCCESS,
  payload: group,
})

export const updateLocationGroupError = error => ({
  type: actionTypes.UPDATE_LOCATION_GROUP_ERROR,
  error,
})

export const deleteLocationGroup = (locationId, groupId) => ({
  type: actionTypes.DELETE_LOCATION_GROUP,
  payload: { locationId, groupId },
})

export const deleteLocationGroupSuccess = (locationId, groupId) => ({
  type: actionTypes.DELETE_LOCATION_GROUP_SUCCESS,
  payload: { locationId, groupId },
})

export const deleteLocationGroupError = error => ({
  type: actionTypes.DELETE_LOCATION_GROUP_ERROR,
  payload: error,
})

export const createLocationGroup = (groupName) => ({
  type: actionTypes.CREATE_LOCATION_GROUP,
  payload: groupName,
})

export const createLocationGroupSuccess = (group) => ({
  type: actionTypes.CREATE_LOCATION_GROUP_SUCCESS,
  payload: group,
})

export const createLocationGroupError = (error) => ({
  type: actionTypes.CREATE_LOCATION_GROUP_ERROR,
  payload: error,
})

export const fetchLocationGroupDetails = (groupId) => ({
  type: actionTypes.FETCH_LOCATION_GROUP_DETAILS,
  payload: groupId,
});

export const fetchLocationGroupDetailsSuccess = (details) => ({
  type: actionTypes.FETCH_LOCATION_GROUP_DETAILS_SUCCESS,
  payload: details,
});

export const fetchLocationGroupDetailsError = (error) => ({
  type: actionTypes.FETCH_LOCATION_GROUP_DETAILS_ERROR,
  payload: error,
});
