import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchLocationGroups,
} from "../../store/locationGroup/actions"
import { fetchSoldOut } from "../../store/soldout/actions"
import Select from "react-select"
import makeAnimated from "react-select/animated"

import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import "jspdf-autotable"
import "./ipaexg-normal"

import { FaBurn } from "react-icons/fa"

const SoldOutLocationsList = () => {
  const dispatch = useDispatch()
  // const isLoading = useSelector(state => state.locationGroupsReducer.loading);
  const allGroups =
    useSelector(state => state.locationGroupsReducer.allGroups) || []
  const soldOutData =
    useSelector(state => state.soldOutReducer.soldOutData) || []
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [includeCloseToSoldOut, setIncludeCloseToSoldOut] = useState(false)

  const [showOnlySoldOut, setShowOnlySoldOut] = useState(false)

  const [selectedLocations, setSelectedLocations] = useState([])
  const [displayResults, setDisplayResults] = useState(false)
  const isLoading = false // 仮のローディング状態管理

  const toggleSelection = locId => {
    setSelectedLocations(prevSelected =>
      prevSelected.includes(locId)
        ? prevSelected.filter(id => id !== locId)
        : [...prevSelected, locId]
    )
  }

  useEffect(() => {
    dispatch(fetchLocationGroups())
    dispatch(fetchSoldOut())
  }, [dispatch])

  // グループの選択肢を準備
  const groupOptions = allGroups.map(group => ({
    value: group.id,
    label: group.name,
  }))

  // グループ選択ハンドラ
  const handleGroupChange = selectedOption => {
    setSelectedGroup(selectedOption)

    // `selectedOption` からグループIDの配列を抽出
    const groupIds = selectedOption.map(option => option.value)
    // 抽出したグループIDの配列を `fetchSoldOut` アクションに渡す
    dispatch(fetchSoldOut(groupIds))

    // 選択されたグループに属するロケーションのみをフィルタリング
    const filteredLocations = soldOutData.filter(location =>
      groupIds.includes(location.groupId)
    ) // groupIdは各locationがグループIDを持っていると仮定
    setSelectedLocations(filteredLocations.map(location => location.loc_id))

    // 集計結果をクリアし、PDF出力ボタンを非活性化
    setDisplayResults([])
    setIsReplenishmentCalculated(false)
  }

  // 「売り切れ間近も含める」チェックボックスのハンドラ
  const handleIncludeCloseToSoldOutChange = e => {
    setIncludeCloseToSoldOut(e.target.checked)
  }

  const [visibleStocks, setVisibleStocks] = useState({})

  const toggleVisibleStocks = locId => {
    setVisibleStocks(prev => ({
      ...prev,
      [`${locId}`]: !prev[`${locId}`],
    }))
  }

  const calculateReplenishment = () => {
    const replenishmentMap = new Map()

    // 選択されたロケーションのみを考慮
    soldOutData.forEach(location => {
      if (selectedLocations.includes(location.loc_id)) {
        location.stocks.forEach(stock => {
          // 「売り切れ商品/間近商品のみ」がチェックされている場合
          if (showOnlySoldOut) {
            // current_stock が 0、または 3 以下の場合に処理を実行
            if (stock.current_stock === 0 || stock.current_stock <= 3) {
              const { goods_name, full_stock, current_stock } = stock
              const replenishmentAmount = full_stock - current_stock

              if (!replenishmentMap.has(goods_name)) {
                replenishmentMap.set(goods_name, replenishmentAmount)
              } else {
                replenishmentMap.set(
                  goods_name,
                  replenishmentMap.get(goods_name) + replenishmentAmount
                )
              }
            }
          } else {
            // 「売り切れ商品/間近商品のみ」がチェックされていない場合は、current_stock が 0 の場合のみ計算
            if (stock.current_stock < stock.full_stock) {
              const { goods_name, full_stock, current_stock } = stock
              const replenishmentAmount = full_stock - current_stock

              if (!replenishmentMap.has(goods_name)) {
                replenishmentMap.set(goods_name, replenishmentAmount)
              } else {
                replenishmentMap.set(
                  goods_name,
                  replenishmentMap.get(goods_name) + replenishmentAmount
                )
              }
            }
          }
        })
      }
    })

    const replenishmentData = Array.from(
      replenishmentMap,
      ([goods_name, amount]) => ({ goods_name, amount })
    )
    setDisplayResults(replenishmentData) // 計算結果を表示用の状態に設定

    setIsReplenishmentCalculated(true) // 集計が完了したら、この状態をtrueに設定
  }

  const exportPDF2 = async () => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    })

    // 各ロケーションのデータを加工して合算する
    const processedData = soldOutData.map(location => {
      const processedStocks = {}
      // 在庫数と不足数を商品名ごとに合算
      location.stocks.forEach(stock => {
        if (processedStocks[stock.goods_name]) {
          processedStocks[stock.goods_name].current_stock += stock.current_stock
          processedStocks[stock.goods_name].shortage += stock.shortage
          // 価格は最初に見つかったものを使用
        } else {
          processedStocks[stock.goods_name] = { ...stock }
        }
      })

      return {
        ...location,
        stocks: Object.values(processedStocks),
      }
    })

    // スタイルを定義
    const style = `
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          border: 1px solid #ccc;
          padding: 5px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
        }
        table, th, td {
          border: 1px solid black;
        }
      </style>
    `

    // 加工したデータでPDFを生成
    for (const location of processedData) {
      // HTMLコンテンツを生成
      const pdfHtml = `
        <div style="font-size: 10px;">
          ${style}
          <h2>${location.loc_name}</h2>
          <table>
            <tr>
              <th>商品名</th>
              <th>在庫数</th>
              <th>不足数</th>
              <th>価格</th>
            </tr>
            ${location.stocks
              .map(
                stock => `
              <tr>
                <td>${stock.goods_name || "商品名無し"}</td>
                <td>${stock.current_stock}</td>
                <td>${stock.shortage}</td>
                <td>${stock.price || "価格設定なし"}</td>
              </tr>
            `
              )
              .join("")}
          </table>
        </div>
      `

      const input = document.createElement("div")
      input.innerHTML = pdfHtml
      document.body.appendChild(input)

      await html2canvas(input, { scale: 2 }).then(canvas => {
        const imgData = canvas.toDataURL("image/png")
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth() - 20 // 余白を設定
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight)
      })

      document.body.removeChild(input) // 不要になった要素を削除

      // 最後のページ以外では新しいページを追加
      if (soldOutData.indexOf(location) < soldOutData.length - 1) {
        pdf.addPage()
      }
    }

    pdf.save("sold-out-report.pdf")
  }

  const exportPDF = () => {
    const doc = new jsPDF()
    doc.setFont("ipaexg", "normal")
    let startY = 20 // 初期のY位置

    // 集計結果の出力
    if (displayResults.length > 0) {
      startY += 10 // タイトルの下にスペースを追加

      // 集計結果のテーブルヘッダー
      const tableColumn = ["商品名", "不足数"]
      // 集計結果のデータ
      const tableRows = displayResults.map(result => [
        result.goods_name,
        result.amount.toString(),
      ])

      // 集計結果をテーブル形式で出力
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: startY,
        styles: {
          font: "ipaexg",
          fontSize: 12,
        },
        headStyles: {
          halign: "center", // ヘッダーのテキストを中央揃えに設定
          fontSize: 14,
          fillColor: [0, 51, 102], // ヘッダーの背景色を濃い青色に設定(RGB形式)
        },
        columnStyles: {
          0: { cellWidth: 155 }, // 商品名の列幅
          1: { cellWidth: 35, halign: "center" }, // 在庫数の列幅
        },
        willDrawPage: function (data) {
          // 新しいページにタイトルを描画するための処理
          const doc = data.doc
          const title = "集計結果" // ここでタイトルを設定
          const startY = data.cursor.y // 現在のカーソルのY位置を取得し、それを開始Y座標として使用

          // タイトルを描画
          drawTitle(doc, title, startY - 2) // タイトルの位置を調整
        },
        didDrawPage: function (data) {
          // ヘッダー
          const today = new Date().toLocaleDateString()
          doc.setFontSize(10)
          doc.text(
            today,
            doc.internal.pageSize.getWidth() - 10 - doc.getTextWidth(today),
            10,
            null,
            null,
            "right"
          )

          // フッター
          const pageNumber = `Page ${doc.internal.getNumberOfPages()}`
          const pageWidth = doc.internal.pageSize.getWidth()
          doc.text(
            pageNumber,
            pageWidth / 2,
            doc.internal.pageSize.getHeight() - 10,
            null,
            null,
            "center"
          )
        },
        theme: "grid",
      })
      doc.addPage() // 集計結果の後に新しいページを追加
    }

    // 選択されたロケーションのみを含むようにprocessedDataをフィルタリングして加工
    const processedData = soldOutData
      .filter(location => selectedLocations.includes(location.loc_id)) // 選択されたロケーションのみフィルタリング
      .map(location => {
        const processedStocks = {}
        // 在庫数に基づいてフィルタリングするための閾値
        const stockThreshold = 0

        // フィルタリングを適用して在庫数が閾値以下の商品のみを処理
        const filteredStocks = location.stocks
          .filter(stock => stock.shortage > stockThreshold)
          .map(stock => {
            if (processedStocks[stock.goods_name]) {
              processedStocks[stock.goods_name].current_stock +=
                stock.current_stock
              processedStocks[stock.goods_name].shortage += stock.shortage
            } else {
              processedStocks[stock.goods_name] = { ...stock }
            }
          })

        return {
          loc_name: location.loc_name,
          stocks: Object.values(processedStocks),
        }
      })

    // 各ロケーションのデータをもとにPDFデータを生成
    processedData.forEach((location, index) => {
      startY += 2 // テキストの後にスペースを追加

      const tableColumn = ["商品名", "在庫数", "不足数"]
      const tableRows = location.stocks.map(stock => [
        stock.goods_name || "商品名無し",
        stock.current_stock.toString(),
        stock.shortage.toString(),
      ])

      // ページ数と日付を追加するためのヘッダーとフッターのカスタム関数
      const addHeader = () => {
        // フォントサイズを小さく設定
        doc.setFontSize(10)
        // 出力日付を追加
        const today = new Date().toLocaleDateString()
        doc.text(
          `${today}`,
          doc.internal.pageSize.getWidth() - 20,
          10,
          null,
          null,
          "right"
        )
      }

      const addFooter = () => {
        // フォントサイズを小さく設定
        doc.setFontSize(10)
        // ページ番号を追加
        const pageNumber = `Page ${doc.internal.getNumberOfPages()}`
        const pageWidth = doc.internal.pageSize.getWidth()
        // ページ番号をページの下部中央に配置
        doc.text(
          pageNumber,
          pageWidth / 2,
          doc.internal.pageSize.getHeight() - 10,
          null,
          null,
          "center"
        )
      }

      // autoTableメソッドを呼び出し
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: startY,
        styles: {
          font: "ipaexg",
          fontSize: 12,
        },
        headStyles: {
          halign: "center", // ヘッダーのテキストを中央揃えに設定
          fontSize: 14,
        },
        columnStyles: {
          0: { cellWidth: 120 }, // 商品名の列幅
          1: { cellWidth: 35, halign: "center" }, // 在庫数の列幅
          2: { cellWidth: 35, halign: "center" }, // 補充数の列幅
        },
        willDrawPage: function (data) {
          // 新しいページにタイトルを描画するための処理
          const doc = data.doc
          const title = location.loc_name // ここでタイトルを設定
          const startY = data.cursor.y // 現在のカーソルのY位置を取得し、それを開始Y座標として使用

          // タイトルを描画
          drawTitle(doc, title, startY - 2) // タイトルの位置を調整
        },
        didDrawPage: function (data) {
          addHeader()
          addFooter()
        },
        margin: { left: 12 }, // 右側のマージンを20に設定
        theme: "grid",
        pageBreak: "avoid",
      })

      // 最後のテーブルのfinalYに基づいてstartYを更新して、テーブル間の間隔を調整
      if (doc.autoTable.previous) {
        startY = doc.autoTable.previous.finalY + 20 // テーブル間の間隔を調整
      }
    })

    doc.save("sold-out-report.pdf")
  }

  // `willDrawPage`フック内で実行される関数を定義します。
  function drawTitle(doc, title, startY) {
    doc.setFontSize(14) // タイトルのフォントサイズ
    doc.text(title, 14, startY) // タイトルの描画
  }

  const [maxHeight, setMaxHeight] = useState("400px") // 初期値として400pxを設定

  useEffect(() => {
    // 画面の高さの8割に基づいてmaxHeightを設定する関数
    const updateMaxHeight = () => {
      const newMaxHeight = window.innerHeight * 0.7 // 画面の高さの70%
      setMaxHeight(`${newMaxHeight}px`)
    }

    // コンポーネントのマウント時に実行
    updateMaxHeight()

    // ウィンドウのリサイズ時にも実行
    window.addEventListener("resize", updateMaxHeight)

    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => window.removeEventListener("resize", updateMaxHeight)
  }, [])

  const [isReplenishmentCalculated, setIsReplenishmentCalculated] =
    useState(false) // 新しい状態変数を追加

  const [selectAll, setSelectAll] = useState(false)

  const toggleSelectAll = () => {
    setSelectAll(!selectAll)

    // 全ロケーション選択または選択解除
    if (!selectAll) {
      setSelectedLocations(soldOutData.map(location => location.loc_id))
    } else {
      setSelectedLocations([])
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ maxWidth: "95%" }}>
          <Breadcrumbs
            maintitle="メイン"
            title="管理"
            breadcrumbItem="売り切れロケーション一覧"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col className="mx-auto">
              <Card>
                <CardBody style={{ maxHeight: maxHeight, overflowY: "auto" }}>
                  <CardTitle>売り切れロケーション一覧</CardTitle>
                  <FormGroup>
                    <Label htmlFor="groupSelect">グループで検索</Label>
                    <Select
                      id="groupSelect"
                      components={makeAnimated()}
                      options={groupOptions}
                      onChange={handleGroupChange}
                      value={selectedGroup}
                      isMulti
                      closeMenuOnSelect={false}
                      placeholder="グループを選択..."
                    />
                  </FormGroup>
                  <Col className="text-center">
                    <Button
                      color="info"
                      onClick={toggleSelectAll}
                      style={{
                        width: "150px",
                        marginRight: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      {selectAll ? "全解除" : "全選択"}
                    </Button>
                  </Col>
                  <div id="pdf-content">
                    {soldOutData.map(location => (
                      <Col
                        key={location.loc_id}
                        md={6}
                        lg={8}
                        className="mx-auto"
                      >
                        <Card
                          className="mb-3"
                          style={{ border: "1px solid black" }}
                        >
                          <CardBody>
                            <CardTitle
                              tag="h5"
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold", // テキストを太文字にする
                                color: selectedLocations.includes(
                                  location.loc_id
                                )
                                  ? "white"
                                  : "black", // 選択時はテキストを白色に
                                backgroundColor: selectedLocations.includes(
                                  location.loc_id
                                )
                                  ? "#ff69b4"
                                  : "", // 背景色を赤に近いピンクに
                                padding: "5px", // タイトル周りにパディングを追加
                                border: "1px solid black",
                              }}
                              onClick={() => toggleSelection(location.loc_id)}
                            >
                              {location.loc_name}
                            </CardTitle>
                            <div
                              className="stock-header"
                              onClick={() =>
                                toggleVisibleStocks(location.loc_id)
                              }
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              <span style={{ flex: 4 }}>商品名</span>
                              <span style={{ flex: 1, textAlign: "right" }}>
                                在庫数
                              </span>
                              <span style={{ flex: 1, textAlign: "right" }}>
                                不足数
                              </span>
                            </div>
                            {location.stocks.map((stock, index) => {
                              const stockThreshold = includeCloseToSoldOut
                                ? 5
                                : 0
                              return (
                                stock.current_stock <= stockThreshold && (
                                  <div
                                    key={index}
                                    onClick={() =>
                                      toggleVisibleStocks(location.loc_id)
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    <span style={{ flex: 4 }}>
                                      {parseFloat(
                                        stock.sales_amount_velocity
                                      ) >= 1500 && (
                                        <span style={{ color: "red" }}>
                                          <FaBurn />
                                        </span>
                                      )}
                                      {stock.goods_name}
                                    </span>
                                    <span
                                      style={{
                                        flex: 1,
                                        color:
                                          stock.current_stock === 0
                                            ? "red"
                                            : "blue",
                                        textAlign: "right",
                                      }}
                                    >
                                      {stock.current_stock}
                                    </span>
                                    <span
                                      style={{ flex: 1, textAlign: "right" }}
                                    >
                                      {stock.shortage}
                                    </span>
                                  </div>
                                )
                              )
                            })}
                            {location.stocks.map((stock, index) => {
                              const stockThreshold = includeCloseToSoldOut
                                ? 3
                                : 0
                              return (
                                stock.current_stock > stockThreshold && (
                                  <div
                                    key={index}
                                    className={`stock-details ${
                                      visibleStocks[`${location.loc_id}`]
                                        ? "visible"
                                        : ""
                                    }`}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    <span style={{ flex: 4 }}>
                                      {parseFloat(
                                        stock.sales_amount_velocity
                                      ) >= 1500 && (
                                        <span style={{ color: "red" }}>
                                          <FaBurn />
                                        </span>
                                      )}
                                      {stock.goods_name}
                                    </span>
                                    <span
                                      style={{
                                        flex: 1,
                                        color:
                                          stock.current_stock > 3
                                            ? "black"
                                            : "blue",
                                        textAlign: "right",
                                      }}
                                    >
                                      {stock.current_stock}
                                    </span>
                                    <span
                                      style={{ flex: 1, textAlign: "right" }}
                                    >
                                      {stock.shortage}
                                    </span>
                                  </div>
                                )
                              )
                            })}
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Col className="text-center">
            <Button
              color="primary"
              onClick={calculateReplenishment}
              disabled={selectedLocations.length === 0}
              style={{ width: "150px", marginRight: "10px", marginTop: "15px" }} // 右側のボタンとの間隔を設定
            >
              不足数の集計
            </Button>
            <Button
              color="danger"
              onClick={exportPDF}
              disabled={
                !isReplenishmentCalculated || selectedLocations.length === 0
              } // 集計が行われていない場合には無効化
              style={{ width: "150px", marginTop: "15px" }}
            >
              PDFを出力
            </Button>
          </Col>
          <div style={{ marginBottom: "20px" }}></div>
          {displayResults && (
            <Col key={location.loc_id} md={8} lg={8} className="mx-auto">
              <Card className="mb-3" style={{ border: "1px solid black" }}>
                <CardBody>
                  <CardTitle
                    tag="h5"
                    style={{
                      fontWeight: "bold",
                      padding: "5px",
                      border: "1px solid black",
                    }}
                  >
                    商品名と不足数
                  </CardTitle>
                  <div
                    className="stock-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    <span style={{ flex: 5, marginLeft: "0.5rem" }}>
                      商品名
                    </span>
                    <span
                      style={{
                        flex: 1,
                        textAlign: "right",
                        marginRight: "1rem",
                      }}
                    >
                      不足数
                    </span>
                  </div>
                  {displayResults.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flex: 5,
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <span style={{ flex: 5, marginLeft: "0.5rem" }}>
                        {item.goods_name}
                      </span>
                      <span
                        style={{
                          flex: 1,
                          textAlign: "right",
                          marginRight: "1rem",
                        }}
                      >
                        {item.amount}
                      </span>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SoldOutLocationsList
