// backend_helper.js

import { get, post, put, del } from "../api_helper";
import * as url from "../url_helper";

// ロケーションの取得
const fetchLocations = async () => {
  try {
    const response = await get(url.GET_LOCATIONS);
    return response;
  } catch (error) {
    throw error;
  }
};

// ロケーションの更新
const updateLocation = async (locationId, locationData) => {
  try {
    const response = await put(`${url.PUT_LOCATION}/${locationId}`, locationData);
    return response;
  } catch (error) {
    throw error;
  }
};

// ロケーションの登録
const registerLocation = async (locationData) => {
  try {
    const response = await post(url.POST_LOCATION, locationData);
    return response;
  } catch (error) {
    throw error;
  }
};

// ロケーションの削除
const deleteLocation = async (locationId) => {
  try {
    const response = await del(`${url.DELETE_LOCATION}/${locationId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export { fetchLocations, updateLocation, registerLocation, deleteLocation };
