// api/authApi.js

import { get } from "../api_helper"
import * as url from "../url_helper"

// Login Method
const getSales = async (
  goodsBrand,
  locId,
  timePeriod,
  currentDate,
  previousDate,
  pageNumber,
  itemsCountPerPage,
  startDate,
  endDate,
  locationGroup  // 追加
) => {
  try {
    let re_timePeriod = ""
    if (timePeriod === "月") {
      re_timePeriod = "year"
    } else if (timePeriod === "日") {
      re_timePeriod = "month"
    } else if (timePeriod === "時") {
      re_timePeriod = "day"
    } else if (timePeriod === "3か月") {
      re_timePeriod = "threeMonth"
    } else {
      re_timePeriod = timePeriod
    }

    const currentDateStr = formatDate(currentDate)
    const previousDateStr = formatDate(previousDate)

    const queryParams = new URLSearchParams()

    if (goodsBrand) {
      queryParams.append("goodsBrand", goodsBrand)
    }
    if (locId) {
      queryParams.append("locId", locId)
    }
    queryParams.append("timePeriod", re_timePeriod)
    queryParams.append("currentDate", currentDateStr)
    queryParams.append("previousDate", previousDateStr)
    queryParams.append("page", pageNumber)
    queryParams.append("items_per_page", itemsCountPerPage)
    if (startDate) {
      // Check if startDate is provided and append
      queryParams.append("start_date", startDate)
    }
    if (endDate) {
      // Check if endDate is provided and append
      queryParams.append("end_date", endDate)
    }
    if (locationGroup) {
      queryParams.append("location_group", locationGroup)  // locationGroupパラメータを追加
    }

    const response = await get(`${url.GET_SALES}?${queryParams.toString()}`)
    return { data: response }
  } catch (error) {
    throw error
  }
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

export { getSales }
