import * as types from "./actionTypes"

const initialState = {
  locations: [],
  allGroups: [],
  loading: false,
  error: null,
}

function locationReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LOCATIONS:
      return { ...state, loading: true }
    case types.FETCH_LOCATIONS_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        locations: action.payload.locations,
        allGroups: action.payload.all_groups
      }
    case types.FETCH_LOCATIONS_ERROR:
      return { ...state, loading: false, error: action.payload }
    case types.UPDATE_LOCATION_DATA:
    case types.REGISTER_LOCATION_DATA:
    case types.DELETE_LOCATION_DATA:
      return { ...state, loading: true }
    case types.UPDATE_LOCATION_DATA_SUCCESS:
    case types.REGISTER_LOCATION_DATA_SUCCESS:
    case types.DELETE_LOCATION_DATA_SUCCESS:
      return { 
        ...state, 
        loading: false,
        // ここで必要に応じてlocationsやallGroupsを更新
      }
    case types.UPDATE_LOCATION_DATA_ERROR:
    case types.REGISTER_LOCATION_DATA_ERROR:
    case types.DELETE_LOCATION_DATA_ERROR:
      return { ...state, loading: false, error: action.payload }
    // 他の既存のアクションタイプの処理...
    default:
      return state;
  }
}

export default locationReducer;
