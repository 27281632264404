import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import CommonComponent from "../CommonComponent"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchCustomersDataRequest,
  updateCustomerDataRequest,
} from "../../store/customers/actions"
import { useLocation } from "react-router-dom"
import Pagination from "react-js-pagination"

const Customers = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const loc_id_from_url = Number(params.get("loc_id"))

  const storedLoc = Number(localStorage.getItem("selectedLoc"))
  const initialIpcsValue = loc_id_from_url || storedLoc || ""
  const [locId, setLoc] = useState(initialIpcsValue)

  const customersData = useSelector(
    state => state.customersReducer.customersData
  )
  const isLoading = useSelector(state => state.customersReducer.loading)

  const [currentDate, setCurrentDate] = useState(new Date())
  const dispatch = useDispatch()
  const [vendor_id, setVendor] = useState(null)

  const [genderFilter, setGenderFilter] = useState("")
  const [ageRangeFilter, setAgeRangeFilter] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [freeWordSearch, setFreeWordSearch] = useState("")
  const [verifyFilter, setVerifyFilter] = useState("")

  // ページング機能
  const totalItems = useSelector(state => state.customersReducer.totalItems) // サーバーから取得
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const [locGroup, setLocGroup] = useState(null)

  useEffect(() => {
    dispatch(
      fetchCustomersDataRequest({
        genderFilter,
        ageRangeFilter,
        startDate,
        endDate,
        freeWordSearch,
        verifyFilter,
        locId,
        currentPage,
        itemsPerPage,
        locGroup
      })
    )
  }, [
    dispatch,
    genderFilter,
    ageRangeFilter,
    startDate,
    endDate,
    freeWordSearch,
    verifyFilter,
    locId,
    currentPage,
  ]) // Add currentPage here

  const updateCustomer = (index, customer) => {
    // Verifyが選択状態になるように更新
    customer.is_verified = true

    dispatch(
      updateCustomerDataRequest(
        index,
        customer,
        genderFilter,
        ageRangeFilter,
        startDate,
        endDate,
        freeWordSearch,
        locId
      )
    )
  }

  const getPageNumbers = () => {
    let pages = []
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 2 && i <= currentPage + 2)
      ) {
        pages.push(i)
      } else if (i === currentPage - 3 || i === currentPage + 3) {
        pages.push("...")
      }
    }
    return pages
  }

  const displayItems = customersData

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="顧客属性変更" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col xs={12}>
              <Card className="bg-light mb-4">
                <CardBody>
                  <CommonComponent
                    showGoodsBrand={false}
                    showDate={false}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    onlyCamera={true}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                  />
                </CardBody>
                <CardBody>
                  <FormGroup row>
                    <Label htmlFor="verifyFilter" sm={1}>
                      Verify:
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="select"
                        name="verifyFilter"
                        id="verifyFilter"
                        onChange={e => setVerifyFilter(e.target.value)}
                      >
                        <option value="">すべて</option>
                        <option value="1">Verified</option>
                        <option value="0">Not Verified</option>
                      </Input>
                    </Col>
                    <Label htmlFor="genderFilter" sm={1}>
                      性別:
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="select"
                        name="genderFilter"
                        id="genderFilter"
                        onChange={e => setGenderFilter(e.target.value)}
                      >
                        <option value="">すべて</option>
                        <option value="male">男性</option>
                        <option value="female">女性</option>
                      </Input>
                    </Col>
                    <Label htmlFor="ageRangeFilter" sm={1}>
                      年代:
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="select"
                        name="ageRangeFilter"
                        id="ageRangeFilter"
                        onChange={e => setAgeRangeFilter(e.target.value)}
                      >
                        <option value="">すべて</option>
                        <option value="0">0-9</option>
                        <option value="1">10-19</option>
                        <option value="2">20-29</option>
                        <option value="3">30-39</option>
                        <option value="4">40-49</option>
                        <option value="5">50-59</option>
                        <option value="6">60+</option>
                      </Input>
                    </Col>
                    <Label htmlFor="startDate" sm={1}>
                      開始日:
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="date"
                        name="startDate"
                        id="startDate"
                        onChange={e => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Label htmlFor="endDate" sm={1}>
                      終了日:
                    </Label>
                    <Col sm={2}>
                      <Input
                        type="date"
                        name="endDate"
                        id="endDate"
                        onChange={e => setEndDate(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={selectedPage => setCurrentPage(selectedPage)}
            itemClass="page-item"
            linkClass="page-link"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Verify</th>
                        <th>Customer ID</th>
                        <th>売上日</th>
                        <th>自販機名/商品名</th>
                        <th>性別</th>
                        <th>年代</th>
                        <th>画像</th>
                        {/* Add other fields as needed */}
                      </tr>
                    </thead>
                    <tbody>
                      {displayItems &&
                        displayItems.map((customer, index) => (
                          <tr key={index}>
                            <td>
                              <Input
                                type="checkbox"
                                checked={customer.is_verified === true}
                                onChange={() =>
                                  updateCustomer(index, {
                                    ...customer,
                                    is_verified: !customer.is_verified,
                                  })
                                }
                              />
                            </td>
                            <td>{customer.customer_id}</td>
                            <td>{customer.order_time}</td>
                            <td>
                              {customer.ipc_name} / {customer.goods_name}
                            </td>{" "}
                            {/* 自販機名と商品名を1つの列に表示 */}
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  style={{ margin: "5px 0", width: "60px" }} // 余白の調整
                                  className={
                                    customer.seg1 === "M" ? "selected" : ""
                                  }
                                  onClick={() =>
                                    updateCustomer(index, {
                                      ...customer,
                                      seg1: "M",
                                    })
                                  }
                                >
                                  男性
                                </button>
                                <button
                                  style={{ margin: "5px 0", width: "60px" }} // 余白の調整
                                  className={
                                    customer.seg1 === "F" ? "selected" : ""
                                  }
                                  onClick={() =>
                                    updateCustomer(index, {
                                      ...customer,
                                      seg1: "F",
                                    })
                                  }
                                >
                                  女性
                                </button>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {" "}
                                {/* flexDirectionを追加 */}
                                {[
                                  "0-9",
                                  "10-19",
                                  "20-29",
                                  "30-39",
                                  "40-49",
                                  "50-59",
                                  "60+",
                                ].map((range, idx) => (
                                  <button
                                    key={idx}
                                    style={{ margin: "5px 0", width: "60px" }} // 余白の調整
                                    className={
                                      parseInt(customer.seg2) === idx
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() =>
                                      updateCustomer(index, {
                                        ...customer,
                                        seg2: idx.toString(),
                                      })
                                    }
                                  >
                                    {range}
                                  </button>
                                ))}
                              </div>
                            </td>
                            <td
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={`data:image/jpeg;base64,${customer.head_img}`}
                                alt="head image"
                                style={{ width: "200px" }}
                              />
                              <img
                                src={`data:image/jpeg;base64,${customer.scene_img}`}
                                alt="scene image"
                                style={{ width: "150px" }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
                <div className="pagination">
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    前のページ
                  </button>
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    次のページ
                  </button>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Customers
