import { startOfMonth, subDays, subMonths, subYears } from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchGraphData } from "../../store/flows/actions"
import BarChart from "../AllCharts/chartist/barchart"
import CommonComponent from "../CommonComponent"
import ErrorMessage from "../Common/ErrorMessage"

BarChart.defaultProps = {
  labels: [],
  data: [],
}

const Flows = () => {
  const [goodsBrand, setGoodsBrand] = useState(null)
  const [locId, setLoc] = useState("")
  const [timePeriod, setTimePeriod] = useState("日")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [vendor_id, setVendor] = useState(null)
  const [locGroup, setLocGroup] = useState(null)
  const graphData = useSelector(state => state.flowsReducer.graphData)
  const isLoading = useSelector(state => state.flowsReducer.loading)

  const dispatch = useDispatch()

  const results = graphData && graphData.data ? graphData.data.results : []
  const label = graphData && graphData.data ? graphData.data.label : ""
  const labels = results.length ? results.map(item => item.month) : []
  const data = results.length ? results.map(item => item.count) : []

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return currentDate
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  useEffect(() => {
    if (locId !== "") {
      dispatch(
        fetchGraphData(goodsBrand, locId, timePeriod, currentDate, previousDate, locGroup)
      )
    }
  }, [locId, currentDate, previousDate])

  const handleBarClick = barIndex => {
    // クリックされたバーのラベルに基づいて日付情報を取得
    const selectedLabel = labels[barIndex]
    let currentDate = null

    if (timePeriod === "月") {
      // ラベルから年と月を抽出
      const [year, month] = selectedLabel.split("-").map(Number)

      // 月の最後の日を取得するために次の月の0日（前月の最後の日）を取得
      currentDate = new Date(year, month, 0)

      setTimePeriod("日")
      setCurrentDate(currentDate)
    } else if (timePeriod === "日") {
      const dateString = selectedLabel.split(" ")[0]

      // 'T14:59:59.999Z' を追加して ISO 8601 形式に変換
      currentDate = new Date(`${dateString}T14:59:59.999Z`)
      setTimePeriod("時")
      setCurrentDate(currentDate)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="人流カウント" />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                      }}
                    >
                      <div style={{ color: "white", fontSize: "2em" }}>
                        Loading...
                      </div>
                    </div>
                  )}
                  <CommonComponent
                    showGoodsBrand={false}
                    goodsBrand={goodsBrand}
                    setGoodsBrand={setGoodsBrand}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    onlyCamera={true}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>人流カウント</CardTitle>
                  <BarChart
                    labels={labels}
                    data={data}
                    label={label}
                    onBarClick={handleBarClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Flows
