import { call, put, takeEvery } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"
import * as api from "../../helpers/api/locationApi"

// ロケーションデータを更新するSaga
function* updateLocationSaga(action) {
  try {
    // ロケーションIDとロケーションデータをペイロードから抽出
    const { locationId, locationData } = action.payload;

    // API関数を呼び出し、ロケーションデータを更新
    const response = yield call(api.updateLocation, locationId, locationData);

    yield put(actions.updateLocationDataSuccess(response))
  } catch (error) {
    // エラーが発生した場合、エラーアクションをディスパッチ
    yield put(actions.updateLocationDataError(error));
  }
}

// ロケーションデータを登録するSaga
function* registerLocationSaga(action) {
  try {
    const { ipcNo, locationData } = action.payload;
    const response = yield call(api.registerLocation, locationData);

    yield put(actions.registerLocationDataSuccess(response));
  } catch (error) {
    yield put(actions.registerLocationDataError(error));
  }
}

// ロケーションデータを更新するSaga
function* deleteLocationSaga(action) {
  try {
    // ロケーションIDとロケーションデータをペイロードから抽出
    const { locationId } = action.payload;

    // API関数を呼び出し、ロケーションデータを更新
    const response = yield call(api.deleteLocation, locationId);

    yield put(actions.deleteLocationDataSuccess(response))
  } catch (error) {
    // エラーが発生した場合、エラーアクションをディスパッチ
    yield put(actions.deleteLocationDataError(error));
  }
}

function* fetchLocationsSaga() {
  try {
    const response = yield call(api.fetchLocations)
    yield put(actions.fetchLocationsSuccess(response))
  } catch (error) {
    yield put(actions.fetchLocationsError(error))
  }
}

// ロケーション処理に関するSagaを監視
function* locationSaga() {
  yield takeEvery(actionTypes.FETCH_LOCATIONS, fetchLocationsSaga)
  yield takeEvery(actionTypes.UPDATE_LOCATION_DATA, updateLocationSaga)
  yield takeEvery(actionTypes.REGISTER_LOCATION_DATA, registerLocationSaga)
  yield takeEvery(actionTypes.DELETE_LOCATION_DATA, deleteLocationSaga)
}

export default locationSaga
