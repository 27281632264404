import React, { useState, useEffect, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  CardTitle,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchLocations,
  updateLocationData,
} from "../../store/location/actions"
import { 
  fetchLocationGroups, 
  createLocationGroup, 
  fetchLocationGroupDetails,
  updateLocationGroup,
  deleteLocationGroup
} from "../../store/locationGroup/actions"
import $ from "jquery"
import "select2/dist/css/select2.min.css"
import "select2"

const LocationsList = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.locationReducer.loading)
  const locationsData =
    useSelector(state => state.locationReducer.locations) || []
  const allGroups =
    useSelector(state => state.locationReducer.allGroups) || []
  const locationGroups = useSelector(state => state.locationGroupsReducer.locationGroups) || []
  const currentGroupDetails = useSelector(state => state.locationGroupsReducer.currentGroupDetails) || null

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false)
  const [newGroupName, setNewGroupName] = useState("")

  const [searchTerm, setSearchTerm] = useState("")
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  })

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editingGroup, setEditingGroup] = useState(null)
  const [groupLocations, setGroupLocations] = useState([])
  const [nonGroupLocations, setNonGroupLocations] = useState([])

  const [selectedNonGroupLocations, setSelectedNonGroupLocations] = useState([]);
  const [selectedGroupLocations, setSelectedGroupLocations] = useState([]);
  const [editingGroupName, setEditingGroupName] = useState("");
  const [locationSearchTerm, setLocationSearchTerm] = useState("");  // 追加: ロケーション検索用のstate

  // ソートと検索を組み合わせたロケーションデータの計算
  const sortedAndFilteredLocations = useMemo(() => {
    return locationsData
      .filter(loc => {
        // ステータスに対する検索処理
        const statusMatch =
          (searchTerm.toLowerCase() === "稼働" && loc.val_flg) ||
          (searchTerm.toLowerCase() === "非稼働" && !loc.val_flg)
        // ロケションコードとロケーション名に対する検索処理
        const textMatch =
          loc.loc_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
          loc.loc_name.toLowerCase().includes(searchTerm.toLowerCase())
        // グループ名に対する検索処理
        const groupMatch = loc.groups.some(group =>
          group.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        return statusMatch || textMatch || groupMatch
      })
      .sort((a, b) => {
        // ソートロジックは変更なし
        if (sortConfig.key === null) return 0
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
  }, [locationsData, searchTerm, sortConfig])

  // ソート要求関数
  const requestSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    } else {
      direction = "ascending"
    }
    setSortConfig({ key, direction })
  }

  // 検索バーのハンドラ
  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    dispatch(fetchLocations())
  }, [dispatch])

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(fetchLocationGroups())
    }
  }, [dispatch, isModalOpen])

  useEffect(() => {
    const selectElements = $(".select2").select2({
      tags: true,
      data: allGroups.map(group => ({ id: group.id, text: group.name })),
      maximumSelectionLength: 10,
      tokenSeparators: [",", " "],
      placeholder: "グループを選択してください",
    })

    // 各ロケーションの既存のグループを設定
    locationsData.forEach(location => {
      if (location.groups && location.groups.length > 0) {
        const groupIds = location.groups.map(group => group.id.toString());
        $(`select[data-location-id="${location.loc_id}"]`).val(groupIds).trigger('change.select2');
      }
    });

    selectElements.on("change", function (e) {
      const locationId = $(this).data("location-id")
      const currentSelectedIds = $(this).val() || []
      
      // 該当するロケーションを見つける
      const location = locationsData.find(loc => loc.loc_id === locationId)
      const previousSelectedIds = location?.groups?.map(g => g.id.toString()) || []

      // 削除されたグループIDを特定
      const removedGroupIds = previousSelectedIds.filter(id => !currentSelectedIds.includes(id))
      
      // 追加されたグループIDを特定
      const addedGroupIds = currentSelectedIds.filter(id => !previousSelectedIds.includes(id))

      // 削除されたグループに対してDELETEリクエストを送信
      removedGroupIds.forEach(groupId => {
        console.log(`Deleting group ${groupId} from location ${locationId}`)
        dispatch(deleteLocationGroup(locationId, groupId))
      })

      // 追加されたグループに対してPUTリクエストを送信
      addedGroupIds.forEach(groupId => {
        console.log(`Adding group ${groupId} to location ${locationId}`)
        dispatch(updateLocationGroup(locationId, [groupId]))
      })
    })

    // クリーンアップ関数
    return () => {
      selectElements.off("change")
    }
  }, [allGroups, locationsData, dispatch])

  useEffect(() => {
    if (currentGroupDetails) {
      setEditingGroup(currentGroupDetails)
      setGroupLocations(currentGroupDetails.group_locations)
      setNonGroupLocations(currentGroupDetails.non_group_locations)
      setEditingGroupName(currentGroupDetails.name);  // 初期値を設定
    }
  }, [currentGroupDetails])

  const handleEditClick = (groupId) => {
    dispatch(fetchLocationGroupDetails(groupId))
    setIsEditModalOpen(true)
  }

  const handleNonGroupLocationSelect = (location, isCtrlKey) => {
    if (isCtrlKey) {
      setSelectedNonGroupLocations(prev => 
        prev.includes(location) 
          ? prev.filter(loc => loc !== location)
          : [...prev, location]
      );
    } else {
      setSelectedNonGroupLocations([location]);
    }
  };

  const handleGroupLocationSelect = (location, isCtrlKey) => {
    if (isCtrlKey) {
      setSelectedGroupLocations(prev => 
        prev.includes(location) 
          ? prev.filter(loc => loc !== location)
          : [...prev, location]
      );
    } else {
      setSelectedGroupLocations([location]);
    }
  };

  const handleMoveToGroup = () => {
    setGroupLocations([...groupLocations, ...selectedNonGroupLocations]);
    setNonGroupLocations(nonGroupLocations.filter(loc => !selectedNonGroupLocations.includes(loc)));
    setSelectedNonGroupLocations([]);
  };

  const handleRemoveFromGroup = () => {
    setNonGroupLocations([...nonGroupLocations, ...selectedGroupLocations]);
    setGroupLocations(groupLocations.filter(loc => !selectedGroupLocations.includes(loc)));
    setSelectedGroupLocations([]);
  };

  const handleUpdateGroup = () => {
    if (!editingGroup || !editingGroup.id || !currentGroupDetails) {
      console.error('グループ情報が未定義です');
      return;
    }

    // グループ名とロケーション情報を含むデータを作成
    const updatedGroupData = {
      name: editingGroupName,  // 編集後のグループ名
      group_id: editingGroup.id,
      group_locations: groupLocations.map(loc => loc.id)
    };

    // 更新処理を実行
    dispatch(updateLocationGroup(editingGroup.id, updatedGroupData));
    setIsEditModalOpen(false);
  };

  const filteredLocations =
    searchTerm.length > 0
      ? locationsData.filter(
          loc =>
            loc.loc_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
            loc.loc_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : locationsData

  const getSortDirectionIndicator = columnName => {
    if (!sortConfig || sortConfig.key !== columnName) {
      return "" // ソートが適用されていない場合は何も表示しない
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓"
  }

  // ロケーショングループ管理モーダルの開閉を制御する関数
  const toggleModal = () => {
    const newIsModalOpen = !isModalOpen;
    setIsModalOpen(newIsModalOpen);
    
    // モーダルが閉じられる時（newIsModalOpen が false の時）に fetchLocationGroups を呼び出す
    if (!newIsModalOpen) {
      dispatch(fetchLocationGroups());
    }
  }

  const toggleGroupModal = () => setIsGroupModalOpen(!isGroupModalOpen)

  const handleNewGroupSubmit = (e) => {
    e.preventDefault()
    dispatch(createLocationGroup(newGroupName))
    setNewGroupName("")
    toggleGroupModal()
  }

  // 同様に、編集モーダルを閉じる際にも更新を反映させる
  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    dispatch(fetchLocationGroups());
  }

  // 検索フィルター関数を追加
  const filteredNonGroupLocations = useMemo(() => {
    if (!locationSearchTerm) return nonGroupLocations;
    
    return nonGroupLocations.filter(location => 
      location.loc_name.toLowerCase().includes(locationSearchTerm.toLowerCase()) ||
      location.loc_code.toLowerCase().includes(locationSearchTerm.toLowerCase())
    );
  }, [nonGroupLocations, locationSearchTerm]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="メイン"
            title="管理"
            breadcrumbItem="ロケーション一覧"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <CardTitle>ロケーション一覧</CardTitle>
                    <Button color="primary" onClick={toggleModal}>
                      ロケーショングループ管理
                    </Button>
                  </div>
                  <input
                    type="text"
                    placeholder="検索..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                  <table className="table">
                    <thead>
                      <tr>
                        <th onClick={() => requestSort("val_flg")}>
                          ステータス {getSortDirectionIndicator("val_flg")}
                        </th>
                        <th onClick={() => requestSort("loc_code")}>
                          ロケーションコード{" "}
                          {getSortDirectionIndicator("loc_code")}
                        </th>
                        <th onClick={() => requestSort("loc_name")}>
                          ロケーション名 {getSortDirectionIndicator("loc_name")}
                        </th>
                        <th onClick={() => requestSort("val_date")}>
                          稼働期間 {getSortDirectionIndicator("val_date")}
                        </th>
                        <th>グループ</th>
                        <th onClick={() => requestSort("updated_at")}>
                          更新日 {getSortDirectionIndicator("updated_at")}
                        </th>
                        <th onClick={() => requestSort("company_name")}>
                          企業 {getSortDirectionIndicator("company_name")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(sortedAndFilteredLocations) &&
                        sortedAndFilteredLocations.map(loc => (
                          <tr key={loc.loc_id}>
                            <td>{loc.val_flg}</td>
                            <td>{loc.loc_code}</td>
                            <td>{loc.loc_name}</td>
                            <td>{loc.val_date}</td>
                            <td>
                              <select
                                className="form-control select2"
                                multiple="multiple"
                                style={{ width: "100%" }}
                                data-location-id={loc.loc_id}
                              ></select>
                            </td>
                            <td>{loc.updated_at}</td>
                            <td>{loc.company_name}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ロケーショングループ管理モーダル */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>ロケーショングループ管理</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>グループ名</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(locationGroups) && locationGroups.length > 0 ? (
                locationGroups.map(group => (
                  <tr key={group.id}>
                    <td>{group.name}</td>
                    <td>
                      <Button color="info" size="sm" className="mr-2" onClick={() => handleEditClick(group.id)}>編集</Button>
                      <Button color="danger" size="sm">削除</Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">グループがありません</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Button color="primary" className="mt-3" onClick={toggleGroupModal}>新規グループ追加</Button>
        </ModalBody>
      </Modal>

      {/* 新規グループ追加モーダル */}
      <Modal isOpen={isGroupModalOpen} toggle={toggleGroupModal}>
        <ModalHeader toggle={toggleGroupModal}>新規グループ追加</ModalHeader>
        <ModalBody>
          <form onSubmit={handleNewGroupSubmit}>
            <FormGroup>
              <Label for="newGroupName">グループ名</Label>
              <Input
                type="text"
                name="newGroupName"
                id="newGroupName"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                required
              />
            </FormGroup>
            <Button color="primary" type="submit">追加</Button>
          </form>
        </ModalBody>
      </Modal>

      {/* ロケーショングループ編集モーダル */}
      <Modal isOpen={isEditModalOpen} toggle={handleEditModalClose} size="lg">
        <ModalHeader toggle={handleEditModalClose}>
          ロケーショングループ編集
        </ModalHeader>
        <ModalBody>
          {editingGroup && (
            <div>
              <FormGroup className="mb-4">
                <Label for="groupName">グループ名</Label>
                <Input
                  type="text"
                  id="groupName"
                  value={editingGroupName}
                  onChange={(e) => setEditingGroupName(e.target.value)}
                />
              </FormGroup>
              <p className="text-muted mb-3">
                Ctrlキーを押しながらクリックすると、複数のロケーションを選択できます。
                選択したロケーションは矢印ボタンで移動できます。
              </p>
              <Row>
                <Col md={5}>
                  <h5>グループ外のロケーション</h5>
                  {/* 検索入力フィールドを追加 */}
                  <div className="mb-2">
                    <Input
                      type="text"
                      placeholder="ロケーション名またはコードで検索..."
                      value={locationSearchTerm}
                      onChange={(e) => setLocationSearchTerm(e.target.value)}
                    />
                  </div>
                  <div style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px' }}>
                    {filteredNonGroupLocations.map((location, index) => (
                      <div 
                        key={index} 
                        className={`d-flex justify-content-between align-items-center mb-2 ${selectedNonGroupLocations.includes(location) ? 'bg-light' : ''}`}
                        onClick={(e) => handleNonGroupLocationSelect(location, e.ctrlKey)}
                      >
                        <span>{location.loc_name} ({location.loc_code})</span>
                      </div>
                    ))}
                    {filteredNonGroupLocations.length === 0 && (
                      <div className="text-center text-muted">
                        該当するロケーションがありません
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={2} className="d-flex flex-column align-items-center justify-content-center">
                  <Button color="primary" onClick={handleMoveToGroup} className="mb-2">→</Button>
                  <Button color="primary" onClick={handleRemoveFromGroup}>←</Button>
                </Col>
                <Col md={5}>
                  <h5>グループ内のロケーション</h5>
                  <div style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px' }}>
                    {groupLocations.map((location, index) => (
                      <div 
                        key={index} 
                        className={`d-flex justify-content-between align-items-center mb-2 ${selectedGroupLocations.includes(location) ? 'bg-light' : ''}`}
                        onClick={(e) => handleGroupLocationSelect(location, e.ctrlKey)}
                      >
                        <span>{location.loc_name} ({location.loc_code})</span>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
              <div className="mt-3 text-center">
                <Button color="primary" onClick={handleUpdateGroup}>更新</Button>
                <Button color="secondary" onClick={() => setIsEditModalOpen(false)}>キャンセル</Button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default LocationsList
