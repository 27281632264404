import * as actionTypes from "./actionTypes"

export const fetchSalesLocData = (locId, locGroup) => ({
  type: actionTypes.FETCH_SALES_LOC_DATA_REQUEST,
  payload: { locId, locGroup }
})

export const fetchSalesLocDataSuccess = data => {
  return {
    type: actionTypes.FETCH_SALES_LOC_DATA_SUCCESS,
    payload: data,
  }
}

export const fetchSalesLocDataError = error => {
  return {
    type: actionTypes.FETCH_SALES_LOC_DATA_ERROR,
    error,
  }
}
