// actions.js
import {
  FETCH_SALES_GRAPH_DATA,
  FETCH_SALES_GRAPH_DATA_SUCCESS,
  FETCH_SALES_GRAPH_DATA_ERROR,
} from "./actionTypes"

// Fetch graph data action
export const fetchGraphData = (
  goodsBrand,
  locId,
  timePeriod,
  currentDate,
  previousDate,
  pageNumber,
  itemsCountPerPage,
  startDate,
  endDate,
  locationGroup  // 追加
) => {
  return {
    type: FETCH_SALES_GRAPH_DATA,
    payload: {
      goodsBrand,
      locId,
      timePeriod,
      currentDate,
      previousDate,
      pageNumber,
      itemsCountPerPage,
      startDate,
      endDate,
      locationGroup,  // 追加
    },
  }
}

// Fetch graph data success action
export const fetchGraphDataSuccess = payload => {
  return {
    type: FETCH_SALES_GRAPH_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchGraphDataError = error => {
  return {
    type: FETCH_SALES_GRAPH_DATA_ERROR,
    error,
  }
}
