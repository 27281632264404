import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

// ロケーショングループの取得
const fetchLocationGroups = async () => {
  try {
    const response = await get(url.GET_LOCATION_GROUPS)
    return response
  } catch (error) {
    throw error
  }
}

// ロケーショングループの更新
const updateLocationGroup = async (locationId, groupData) => {
  try {
    const groupId = groupData.group_id ? groupData.group_id : groupData[0]
    const groupLocations = groupData.group_locations ? groupData.group_locations : [locationId]

    const payload = {
      groupLocations: {
        name: groupData.name ,
        groupId: groupId,
        groupLocations: groupLocations  // ロケーションコードの配列
      }
    };
    const response = await put(`${url.PUT_LOCATION_GROUP}/${locationId}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

// ロケーショングループの登録
const registerLocationGroup = async (groupData) => {
  try {
    const response = await post(url.POST_LOCATION_GROUP, groupData)
    return response
  } catch (error) {
    throw error
  }
}

// ロケーショングループの削除
const deleteLocationGroup = async (locationId, groupId) => {
  try {
    const response = await del(`${url.DELETE_LOCATION_GROUP}/${locationId}/${groupId}`)
    return response
  } catch (error) {
    throw error
  }
}

const fetchLocationGroupDetails = async (groupId) => {
  try {
    const response = await get(`${url.GET_LOCATION_GROUPS}/${groupId}`)
    return response
  } catch (error) {
    throw error
  }
}

export { fetchLocationGroups, updateLocationGroup, registerLocationGroup, deleteLocationGroup, fetchLocationGroupDetails }
