import {
  format,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
  subYears,
} from "date-fns"

import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Row,
  Table,
} from "reactstrap"

import { animateScroll as scroll } from "react-scroll"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchGraphData } from "../../store/salesDaily/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import CommonComponent from "../CommonComponent"

const Sales_daily = () => {
  const [locId, setLoc] = useState("")

  const [goodsBrand, setGoodsBrand] = useState(null)

  const [timePeriod, setTimePeriod] = useState("時")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [isOpenAll, setIsOpenAll] = useState(false)
  const [vendor_id, setVendor] = useState(null)
  const [locGroup, setLocGroup] = useState(null)
  const graphData = useSelector(state => state.salesDailyReducer.graphData)
  const isLoading = useSelector(state => state.salesDailyReducer.loading)
  const dispatch = useDispatch()

  const [collapseStates, setCollapseStates] = useState({})

  // クエリパラメータを解析する関数
  const query = new URLSearchParams(location.search)
  const month = query.get("month")
  const loc_id = query.get("loc_id")

  // startDateとendDateステートを追加
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return subDays(currentDate, 1)
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  useEffect(() => {
    if (locId !== "" || locGroup !== null) {
      dispatch(
        fetchGraphData(goodsBrand, locId, timePeriod, currentDate, previousDate, locGroup)
      )
    }
  }, [locId, locGroup])

  useEffect(() => {
    if (graphData && graphData.data && Array.isArray(graphData.data.results)) {
      let initialState = {}
      graphData.data.results.forEach((item, index) => {
        initialState[item.date] = index === 0 ? true : false
      })
      setCollapseStates(initialState)
    }
  }, [graphData])

  const toggleCollapse = date => {
    setCollapseStates({
      ...collapseStates,
      [date]: !collapseStates[date],
    })
  }

  const toggleAll = () => {
    let newState = {}
    for (let date in collapseStates) {
      newState[date] = !isOpenAll
    }
    setIsOpenAll(!isOpenAll)
    setCollapseStates(newState)
  }

  let dateGroupedData = {}

  if (graphData && graphData.data && Array.isArray(graphData.data.results)) {
    for (let item of graphData.data.results) {
      let date = item.date

      if (!dateGroupedData[date]) {
        dateGroupedData[date] = []
      }

      for (let goodsItem of item.goods) {
        dateGroupedData[date].push(goodsItem)
      }
    }
  }

  useEffect(() => {
    if (month && loc_id) {
      const match = month.match(/^(\d{4}-\d{2}-\d{2})/)
      let re_month = null
      if (match) {
        // match[1] には "YYYY-MM-DD" の部分が含まれます
        re_month = match[1]
      } else {
        re_month = month
      }

      const monthDate = parseISO(re_month) // '2023-07-01' の形式にしてDateオブジェクトに変換

      if (loc_id === "null") {
        setLoc(null)
        setLocGroup(null)
      } else {
        setLoc(parseInt(loc_id))
        setLocGroup(null)
      }

      setCurrentDate(monthDate)
    }
  }, [month, loc_id])

  const convertToCSV = data => {
    // CSVのヘッダー
    const headers = ["日付", "品名", "単価", "売上個数", "売上合計"]

    // データ行
    const rows = data.flatMap(day =>
      day.goods.map(good =>
        [
          day.date,
          `"${good.goods_name}"`, // 商品名にカンマがある場合のためにダブルクォートで囲む
          good.price,
          good.sales_number,
          good.sales_sum,
        ].join(",")
      )
    )

    // 全てを結合
    return "\uFEFF" + [headers.join(","), ...rows].join("\n")
  }

  const downloadCSV = data => {
    // CSVをダウンロード
    const csvString = convertToCSV(data)
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)

    // currentDateとpreviousDateをYYYYMMDD形式に変換
    const prefix = "sales_daily_data"
    const formattedCurrentDate = format(new Date(currentDate), "yyyyMMdd")

    // ファイル名にcurrentDateとpreviousDateを追加
    const fileName = `${prefix}_${locId ?? "all"}_${formattedCurrentDate}.csv`
    link.setAttribute("download", fileName)

    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"
  const tableStyle2 =
    window.innerWidth < 500 ? "p-0 bg-secondary" : "mx-auto p-3 bg-secondary"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="日別売上確認" />
          <ErrorMessage />
          <Row>
            <Col>
              <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  <LoadingOverlay isLoading={isLoading} />
                  <CommonComponent
                    showGoodsBrand={false}
                    showDate={false}
                    showAll={true}
                    goodsBrand={goodsBrand}
                    setGoodsBrand={setGoodsBrand}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                    past_loc={true}
                  />
                </CardBody>
                <Col
                  xs={12}
                  md={6}
                  xl={6}
                  className="d-flex align-items-center px-1"
                >
                  <Button
                    id="csvButton"
                    color="info"
                    onClick={() => downloadCSV(graphData.data.results)}
                    className="w-50 mb-2 mx-3"
                  >
                    CSVをダウンロード
                  </Button>
                  <Button
                    color="success"
                    className="w-50 mb-2"
                    onClick={toggleAll}
                  >
                    全部開く
                  </Button>
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid={true}>
          {Object.entries(dateGroupedData).length > 0 ? (
            Object.entries(dateGroupedData).map(([date, items]) => (
              <Card
                key={date}
                className={tableStyle}
                style={{ maxWidth: "750px" }}
              >
                <CardHeader onClick={() => toggleCollapse(date)}>
                  {`${date} (${new Date(date).toLocaleDateString("ja", {
                    weekday: "short",
                  })})`}
                </CardHeader>

                <Collapse isOpen={collapseStates[date]}>
                  <CardBody>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>商品名</th>
                          <th className="sales-price">単価</th>
                          <th className="sales-count">個数</th>
                          <th className="sales-amount">売上</th>
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((item, index) => (
                          <tr key={index}>
                            <td className="w-100">{item.goods_name}</td>
                            <td className="sales-price">
                              {item.price.toLocaleString()}
                            </td>
                            <td className="sales-count">{item.sales_number}</td>
                            <td className="sales-amount">
                              {item.sales_sum.toLocaleString()}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={2}>合計</td>
                          <td className="sales-count">
                            {items
                              .reduce((sum, item) => sum + item.sales_number, 0)
                              .toLocaleString()}
                          </td>
                          <td className="sales-amount">
                            {items
                              .reduce((sum, item) => sum + item.sales_sum, 0)
                              .toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Collapse>
              </Card>
            ))
          ) : (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              データがありません
            </div>
          )}
        </Container>
        <div
          style={{
            position: "fixed",
            bottom: "1em",
            right: "1em",
            opacity: "0.7",
          }}
        >
          <Button onClick={() => scroll.scrollToTop({ duration: 100 })}>
            TOPへ戻る
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Sales_daily
