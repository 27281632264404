// reducer.js
import * as types from './actionTypes';

const initialState = {
  locationGroups: [],
  loading: false,
  error: null,
  currentGroupDetails: null,
};

const locationGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LOCATION_GROUPS:
      return { ...state, loading: true };
    case types.FETCH_LOCATION_GROUPS_SUCCESS:
      return { ...state, loading: false, locationGroups: action.payload };
    case types.FETCH_LOCATION_GROUPS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case types.UPDATE_LOCATION_GROUP:
      return { ...state, loading: true };
    case types.UPDATE_LOCATION_GROUP_SUCCESS:
      return { 
        ...state, 
        locationGroups: action.payload.location_groups, 
        allGroups: action.payload.all_groups, 
        loading: false 
      };
    case types.UPDATE_LOCATION_GROUP_ERROR:
      return { ...state, error: action.payload, loading: false };
    case types.CREATE_LOCATION_GROUP:
      return { ...state, loading: true };
    case types.CREATE_LOCATION_GROUP_SUCCESS:
      return {
        ...state,
        locationGroups: [...state.locationGroups, action.payload],
        loading: false,
      };
    case types.CREATE_LOCATION_GROUP_ERROR:
      return { ...state, error: action.payload, loading: false };
    case types.FETCH_LOCATION_GROUP_DETAILS:
      return { ...state, loading: true };
    case types.FETCH_LOCATION_GROUP_DETAILS_SUCCESS:
      return { ...state, loading: false, currentGroupDetails: action.payload };
    case types.FETCH_LOCATION_GROUP_DETAILS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default locationGroupReducer;
