// src/config.js
export const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:8100"

export const JPRIME_RUN_MODE =
  process.env.REACT_APP_JPRIME_RUN_MODE || "production"

const SYSTEM_NAME = "J-Prime クラウド"

// package.jsonのバージョンを参照するように修正
const { version } = require("../package.json")
const SYSTEM_VERSION = `v${version}`

export { SYSTEM_NAME, SYSTEM_VERSION }
