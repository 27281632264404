// actionTypes.js
export const UPDATE_LOCATION_DATA = 'UPDATE_LOCATION_DATA';
export const UPDATE_LOCATION_DATA_SUCCESS = 'UPDATE_LOCATION_DATA_SUCCESS';
export const UPDATE_LOCATION_DATA_ERROR = 'UPDATE_LOCATION_DATA_ERROR';
export const REGISTER_LOCATION_DATA = 'REGISTER_LOCATION_DATA';
export const REGISTER_LOCATION_DATA_SUCCESS = 'REGISTER_LOCATION_DATA_SUCCESS';
export const REGISTER_LOCATION_DATA_ERROR = 'REGISTER_LOCATION_DATA_ERROR';
export const DELETE_LOCATION_DATA = 'DELETE_LOCATION_DATA';
export const DELETE_LOCATION_DATA_SUCCESS = 'DELETE_LOCATION_DATA_SUCCESS';
export const DELETE_LOCATION_DATA_ERROR = 'DELETE_LOCATION_DATA_ERROR';
export const FETCH_LOCATIONS = "FETCH_LOCATIONS"
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS"
export const FETCH_LOCATIONS_ERROR = "FETCH_LOCATIONS_ERROR"
